import {
  AfterViewInit,
  Component,
  Inject,
  Injectable,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DOCUMENT, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { MenuItem, OverlayPanel } from "primeng/primeng";
import { of, Subject, Subscription } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ActionDispatcher } from "./app state/action-dispatcher/action-dispatcher";
import { SuperBillsService } from "./billings/services/superbill.service";
import { GridPaginationEvent } from "./components/sib-forms/grid/grid.config";
import { ContractService } from "./contracts/services/contract.services";
import { HoardingGroupService } from "./contracts/services/hoarding-group.service";
import { HoardingService } from "./contracts/services/hoardings.service";
import { SupplierVendorService } from "./contracts/services/supplier-vendor.service";
import { PointOfContactService } from "./customers/services/pointofcontact.services";
import { DashboardService } from "./dashboard/service/dashboard.service";
import { DateUtil } from "./helpers/date.util";
import { ErrorUtil } from "./helpers/error.utils";
import * as fileUtils from "./helpers/file.util";
import * as utils from "./helpers/utils";
import { HoardingStatusService } from "./hoardings-operation/services/hoardings-status.service";
import { AuthService } from "./login-service/auth.service";
import { MainComponent } from "./main/main.component";
import { GridLoader } from "./modals/loader-subjects/grid-loader";
import { LoaderSubjects } from "./modals/loader-subjects/loader-subjects";
import { PhotoLoader } from "./modals/loader-subjects/photo-loader";
import { PPTLoader } from "./modals/loader-subjects/pptloader";
import { PptFormatEnum } from "./modals/ppt/ppt-format-enum";
import { PptGenerationData } from "./modals/ppt/pptgenerationdata";
import { NotificatoinsService } from "./services/notifications/notifications.service";
import { CalculationService } from "./services/shared/calculationService";
import { CampaignService } from "./services/shared/campaign.service";
import { CampaignItemService } from "./services/shared/campaignItems.service";
import { ChangesService } from "./services/shared/changes.service";
import { InventoryExpenseService } from "./services/shared/inventory-expenses.service";
import { InventoryService } from "./services/shared/inventory.service";
import { InvoiceChartService } from "./services/shared/invoice-chart.service";
import { KioskService } from "./services/shared/kiosk.service";
import { NotificationsMessages } from "./services/shared/notifications-messages";
import { PlanTemplateService } from "./services/shared/plan-template.service";
import { PlanService } from "./services/shared/plan.service";
import { PptGeneratorService } from "./services/shared/ppt-generator.service";
import { SalesChartService } from "./services/shared/sales-chart.service";
import { SystemService } from "./services/shared/system.service";
import { UserService } from "./services/shared/user.service";
import { VendorService } from "./services/shared/vendor.service";
import { WebNotificationService } from "./services/shared/web-notifications.service";
import { TitleService } from "./services/title.service";
import { AppUrls } from "./services/urls";
import { PendingUnbilledUIService } from "./shared/components/pending-unbilled-tasks/services/pending-unbilled-ui.service";
import { HoardingLightEnum } from "./shared/constants/hoarding-light-enum";
import { ImageTypeEnum } from "./shared/constants/image-type-enum";
import { SIBConstants } from "./shared/constants/SIBConstant";
import { PackagesService } from "./subscription-plans/services/packages.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [PointOfContactService],
})
@Injectable()
export class AppTopBar implements OnInit, AfterViewInit {
  showPassBook = false;

  userProfile: any;
  userCompleteProfile: any;
  title: string;
  appTitleSubscription: Subscription;
  showUsers: boolean = false;
  image: string;
  userData: any;
  profileInitials: any;
  display: boolean = false;
  displaySuggestionDialog: boolean = false;
  helpItems: MenuItem[];
  //---------------------------------------------PPT download variables------------------------------------

  pptloader: PPTLoader = new PPTLoader();
  resetPptloader: PPTLoader = new PPTLoader();
  //---------------------------------------------------------------------------------------------------------

  //-----------------------------------------------XLS download variables------------------------------------

  xlsLoader: PPTLoader = new PPTLoader();
  resetXlsLoader: PPTLoader = new PPTLoader();

  //----------------------------------------------------GridLoader--------------------------------------------
  gridLoader: GridLoader = new GridLoader();

  //----------------------------------------------------PhotoLoader---------------------------------
  photoLoader: PhotoLoader = new PhotoLoader();
  mountingPhotoLoader: PhotoLoader = new PhotoLoader();
  monitoringPhotoLoader: PhotoLoader = new PhotoLoader();
  resetPhotoLoader: PhotoLoader = new PhotoLoader();
  pptGenerationObjects: PptGenerationData[] = [];
  planTermAndCondtions: any[] = [];
  campaignTermAndConditions: any[] = [];
  displayCallDialog: boolean = false;

  isAllImageDownloading:Boolean = false;

  logoUrl: string;
  pptEmailUrl: string;
  tenantId: string;
  isLoading: boolean;

  pptText: string;
  pptRedirectUrl: string;
  defaultLogoUrl: string;
  applicationTitle: string;
  portalBaseUrl: string;

  rollbackToPlan: any;
  resetrollbackToPlanloader = {};
  temporaryId: number = 0;
  showChildren: boolean = false;
  srNo = 1;
  subSr = "a";
  currentSerialNumber: number;
  childrenLength: number;

  @ViewChild("notificationOverlay") notificationOverlay: OverlayPanel;
  showNotifications: boolean = false;
  unreadCount: number = 0;
  notificationsScrolled: boolean = false;
  emailId: any;

  pptsDownloadInProgress: boolean[] = [];
  pptDownloads = [];
  campaignPptDownloads = [];
  $pptDownloadCompletedSubject: Subject<any> = new Subject();

  quoteLoader: PPTLoader = new PPTLoader();
  resetQuoteLoader: PPTLoader = new PPTLoader();

  inventoryPhotoUpdateLoader: PPTLoader = new PPTLoader();
  resetInventoryPhotoUpdateLoader: PPTLoader = new PPTLoader();

  searchEvent: GridPaginationEvent = {
    page: 0,
    size: 50,
    sortField: "updatedDate",
    sortOrder: 1,
    filters: {},
    globalFilter: "",
  };

  chunkWiseList = [];
  processedChunkList = [];
  alreadyProcessedChunks = [];
  DOCUMENTATION_URL = SIBConstants.DOCUMENTATION_URL;

  constructor(
    public app: MainComponent,
    public appTitle: TitleService,
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private loaderSubjects: LoaderSubjects,
    private planService: PlanService,
    private campaignService: CampaignService,
    private pendingUnbilledUIService: PendingUnbilledUIService,
    private invoiceChartService: InvoiceChartService,
    private actionDispatcher: ActionDispatcher,
    private pptGeneratorService: PptGeneratorService,
    private calculationService: CalculationService,
    private notificationServcie: NotificatoinsService,
    private hoardingOperationService: HoardingStatusService,
    private superBillsService: SuperBillsService,
    private systemService: SystemService,
    private vendorService: VendorService,
    private pointOfContactService: PointOfContactService,
    private hoardingService: HoardingService,
    private hoardingGroupService: HoardingGroupService,
    private kioskService: KioskService,
    private contractService: ContractService,
    private salesChartService: SalesChartService,
    private dashboardService: DashboardService,
    private planTemplateService: PlanTemplateService,
    private inventoryService: InventoryService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private pageTitle: Title,
    private inventoryExpensesService: InventoryExpenseService,
    private webNotificationsService: WebNotificationService,
    private packageService: PackagesService,
    private changesService: ChangesService,
    private campaignItemService: CampaignItemService,
    private supplierVendorService: SupplierVendorService
  ) {
    this.userProfile = this.auth.getUserInfo();
    this.emailId = utils.getLoggedInUserEmail();
  }

  ngOnInit() {
    if (
      localStorage.getItem("profileSetOnLogin") &&
      JSON.parse(localStorage.getItem("profileSetOnLogin"))
    ) {
      // if there is any api call to be made on ngoninit then add it in this if clause and in auth.emitproifle subscription
      // and this if condition is in 3 components 1) apptopbar 2)main.component 3) top-section.component
      // This if clause is added to avoid API calls before getting token from auth0
      this.getData();
    }

    this.webNotificationsService.unreadCountSubject.subscribe((action) => {
      if (action === "increment") {
        this.unreadCount++;
      } else if (action === "decrement") {
        this.unreadCount--;
      }
    });
    this.actionDispatcher.enablePassbook.subscribe((value) => {
      if (value) {
        this.showPassBook = value;
      } else {
        this.showPassBook = false;
      }
    });

    this.auth.emitProifle.subscribe((profile) => {
      this.getData();
      this.image = profile.picture;
      this.userProfile = profile;
      this.emailId = profile.email;
      this.getUnreadCount();
    });

    this.userService.user.subscribe((user) => {
      if (user) {
        this.userProfile = user[0];
        let localuser = JSON.parse(localStorage.getItem("userInfo"))[0]
          .user_metadata;
        this.profileInitials =
          localuser.firstname.charAt(0).toUpperCase() +
          localuser.lastname.charAt(0).toUpperCase();
      }
    });

    if (localStorage.getItem("userInfo")) {
      let localuser = JSON.parse(localStorage.getItem("userInfo"))[0]
        .user_metadata;
      this.profileInitials =
        localuser.firstname.charAt(0).toUpperCase() +
        localuser.lastname.charAt(0).toUpperCase();
    }

    this.$pptDownloadCompletedSubject.subscribe((data) => {
      if (this.pptDownloads.length) {
        this.pptloader = this.pptDownloads[0];
        if (this.pptDownloads[0].fileType === "PPT") {
          this.downloadPPT();
        } else if (this.pptDownloads[0].fileType === "PPT_BETA") {
          this.downloadPPTBetaOptimized();
          //   this.downloadPPTBetaCurrent();
          // } else if (this.pptDownloads[0].fileType === "PPT_BETA_OLD") {
          // this.downloadPPTBeta();
        } else if (this.pptDownloads[0].fileType === "GENERATE_AND_SEND_PPT") {
          this.generateAndSendPPT();
        }
      } else if (this.campaignPptDownloads.length) {
        this.pptloader = this.campaignPptDownloads[0];
        if (this.campaignPptDownloads[0].fileType === "PPT") {
          this.downloadCampaignPpt();
        } else if (this.campaignPptDownloads[0].fileType === "PPT_BETA") {
          this.downloadCampaignPptBeta();
        }
      }
    });

    this.loaderSubjects.pptLoader.subscribe((data) => {
      if (data) {
        // this.pptsDownloadInProgress.push(true);
        this.pptloader = _.cloneDeep(data);
        this.pptDownloads.push(this.pptloader);

        if (
          this.pptloader.fileType === "PPT" &&
          this.pptDownloads.length === 1 &&
          !this.campaignPptDownloads.length
        ) {
          this.downloadPPT();
        } else if (
          this.pptloader.fileType === "PPT_BETA" &&
          this.pptDownloads.length === 1 &&
          !this.campaignPptDownloads.length
        ) {
          this.downloadPPTBetaOptimized();
          //   this.downloadPPTBetaCurrent();
          // } else if (
          //   this.pptloader.fileType === "PPT_BETA_OLD" &&
          //   this.pptDownloads.length === 1 &&
          //   !this.campaignPptDownloads.length
          // ) {
          // this.downloadPPTBeta();
        } else if (
          this.pptDownloads[0].fileType === "GENERATE_AND_SEND_PPT" &&
          this.pptDownloads.length === 1 &&
          !this.campaignPptDownloads.length
        ) {
          this.generateAndSendPPT();
        }

        this.loaderSubjects.pptLoader.next(undefined);
      }
    });

    this.loaderSubjects.xlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.gridLoader.subscribe((data) => {
      this.gridLoader = data;
    });

    this.loaderSubjects.isAllImagesDownloading.subscribe((data)=>{
      this.isAllImageDownloading=data;
    })

    this.loaderSubjects.campaignXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadCampaignXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });
    this.loaderSubjects.campaignTrackerXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadCampaignTrackerXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });
    this.loaderSubjects.campaignPptLoader.subscribe((data) => {
      // this.pptsDownloadInProgress.push(true);
      this.pptloader = _.cloneDeep(data);
      this.campaignPptDownloads.push(this.pptloader);
      if (
        data.fileType === "PPT" &&
        this.campaignPptDownloads.length === 1 &&
        !this.pptDownloads.length
      ) {
        this.downloadCampaignPpt();
      } else if (
        data.fileType === "PPT_BETA" &&
        this.campaignPptDownloads.length === 1 &&
        !this.pptDownloads.length
      ) {
        this.downloadCampaignPptBeta();
      }
      this.loaderSubjects.pptLoader.next(undefined);

      //  else {
      //     this.notificationServcie.info('Please select the image type', 'PPT Download');
      // }
    });

    this.loaderSubjects.campaignTrackerPptLoader.subscribe((data) => {
      if (data) {
        this.pptloader = data;
        this.downloadCampaignTrackerPpt();
        this.loaderSubjects.pptLoader.next(undefined);
      }
    });

    this.loaderSubjects.superbillListXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadSuperbillListXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.invoiceXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInvoiceXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.invoiceTallyXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInvoiceTallyXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.migratedInvoiceXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadMigratedInvoiceXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.creditNoteXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadCreditNoteXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.reservedInvoiceXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadReservedInvoiceXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.reservedCreditNoteXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadReservedCNXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.pendingUnbilledXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadPendingUnbilledXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.flexItemsXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadFlexItemsXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.mountingItemsXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadMountingItemsXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.monitoringItemsXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadMonitoringItemsXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.unmountingItemsXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadUnmountingItemsXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.photoLoader.subscribe((data) => {
      if (data) {
        this.photoLoader = data;
        if (this.photoLoader.items && this.photoLoader.items.length > 0) {
          this.downloadImages(this.photoLoader);
        }

        // this.loaderSubjects.photoLoader.next(undefined)
      }
    });

    this.loaderSubjects.pocXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadPocXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.hoardingXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.kioskXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.pillarXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.bqsXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.gantryXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.pickupStandXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.unipoleXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.signboardXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.utilityXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.ledXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.contractXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadContractXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.salesChartXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        // this.downloadContractXls();
        this.downloadSalesChartXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.roiContractDataXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadRoiContractDataXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.roiContractInventoryDataXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadRoiContractInventoryDataXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.untappedRoiDataXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadUntappedRoiDataXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.templateHoardingXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadTemplateXlsx("hoardings");
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.templateHoardingGroupXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadTemplateXlsx("hoardingGroups");
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.templateKioskXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadTemplateXlsx("kiosks");
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.campaignRollbackLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.campaignRollback(data.data);
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.expenseLevel1Loader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadExpenseLevel1Xlsx();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.expenseLevel2Loader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadExpenseLevel2Xlsx();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.mountingReportXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadMounterReport("Mounting Report");
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.monitoringReportXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadMounterReport("Monitoring Report");
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.unmountingReportXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadMounterReport("Unmounting Report");
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchHoardingXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchKioskXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchPillarXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchBqsXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchGantryXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchPickupStandXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchUnipoleXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchSignboardXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchUtilityXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.advanceSearchLedXlsLoader.subscribe((data) => {
      if (data) {
        this.xlsLoader = data;
        this.downloadAdvanceSearchInventoryXls();
        this.loaderSubjects.xlsLoader.next(undefined);
      }
    });

    this.loaderSubjects.confirmationQuoteLoader.subscribe((data) => {
      if (data) {
        this.quoteLoader = data;
        this.downloadConfirmationQuote();
        this.loaderSubjects.confirmationQuoteLoader.next(undefined);
      }
    });
    this.loaderSubjects.inventoryPhotoUpdateLoader.subscribe((data) => {
      if (data) {
        this.inventoryPhotoUpdateLoader = data;
        this.updateInventoryPhotos();
        this.loaderSubjects.inventoryPhotoUpdateLoader.next(undefined);
      }
    });
    // this.loaderSubjects.mountingPhotoLoader.subscribe((data) => {
    //     if (data) {
    //         this.mountingPhotoLoader = data;
    //         if (this.mountingPhotoLoader.items && this.mountingPhotoLoader.items.length > 0) {
    //             this.downloadImages(this.mountingPhotoLoader)
    //         }

    //         // this.loaderSubjects.photoLoader.next(undefined)
    //     }
    // })

    // this.loaderSubjects.monitoringPhotoLoader.subscribe((data) => {
    //     if (data) {
    //         this.monitoringPhotoLoader = data;
    //         if (this.monitoringPhotoLoader.items && this.monitoringPhotoLoader.items.length > 0) {
    //             this.downloadImages(this.monitoringPhotoLoader)
    //         }

    //         // this.loaderSubjects.photoLoader.next(undefined)
    //     }
    // })

    fileUtils.isFileDownloaded.subscribe((data) => {
      if (data) {
        this.createPhotoLoaderResetObject(this.resetPhotoLoader);
        this.loaderSubjects.photoLoader.next(this.resetPhotoLoader);
        fileUtils.isFileDownloaded.next(false);
      }
    });

    this.helpItems = [
      {
        label: "Help & Support",
        command: (event) => {
          this.showHelpDialog();
        },
        visible: utils.checkPermission("view:help-support") ? true : false,
      },
      {
        label: "Suggestion",
        command: (event) => {
          this.showSuggestionDialog();
        },
      },
    ];

    this.notificationOverlay.onAfterHide.subscribe(() => {
      this.hideNotificationOverlay();
    });
  }

  getData() {
    this.getVendorInformation();
    this.systemService
      .get({ module: "PLAN", key: "TERM_AND_CONDITION" }, "/byModule/key")
      .subscribe(
        (response) => {
          if (response) {
            this.planTermAndCondtions = response["value"];
          }
        },
        (error) => {
          this.notificationServcie.error(
            error.error
              ? error.error.message
                ? error.error.message
                : "Some technical issue"
              : error.message,
            "Error!!"
          );
        }
      );

    this.systemService
      .get({ module: "CAMPAIGN", key: "TERM_AND_CONDITION" }, "/byModule/key")
      .subscribe(
        (response) => {
          if (response) {
            this.campaignTermAndConditions = response["value"];
          }
        },
        (error) => {
          this.notificationServcie.error(
            error.error
              ? error.error.message
                ? error.error.message
                : "Some technical issue"
              : error.message,
            "Error!!"
          );
        }
      );
    this.userCompleteProfile = JSON.parse(localStorage.getItem("userInfo"));

    if (this.userProfile) {
      this.image = this.userProfile.picture;
    }

    this.setSupplierDropdown();
  }

  ngAfterViewInit() {
    this.getUnreadCount();
  }

  /**
   * @description gets count for unread notification
   * @author Raveena Nathani
   * @date 2020-05-13
   * @memberof AppTopBar
   */
  getUnreadCount() {
    if (this.emailId) {
      this.webNotificationsService
        .get(null, AppUrls.GET_UNREAD_COUNT + "/" + this.emailId)
        .subscribe((res) => {
          if (res["data"]) {
            this.unreadCount = res["data"];
          }
        });
    }
  }

  downloadPPT() {
    if (this.pptDownloads[0].pptData) {
      if (this.pptDownloads[0].customIds.length === 0) {
        this.notificationServcie.error(
          "PPT Download Error",
          "Please Try Again!"
        );
      } else {
        if (
          new Date(this.pptDownloads[0].pptData.updatedDate).valueOf() >
            new Date(this.pptDownloads[0].pptUrls.createdDate).valueOf() ||
          !this.pptDownloads[0].pptUrls ||
          !Object.keys(this.pptDownloads[0].pptUrls.formatToUrls).length ||
          !this.pptDownloads[0].format ||
          !this.pptDownloads[0].pptUrls.formatToUrls[
            this.pptDownloads[0].format
          ] ||
          !this.pptDownloads[0].pptUrls.formatToUrls[
            this.pptDownloads[0].format
          ].url
        ) {
          this.planService
            .downloadFile(
              this.pptDownloads[0].pptData.id,
              this.pptDownloads[0].fileType,
              this.pptDownloads[0].ids,
              null,
              this.pptDownloads[0].format
            )
            .subscribe((response: any) => {
              let blob;
              const anchor = document.createElement("a");
              blob = new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              });
              anchor.download =
                this.pptDownloads[0].pptData.displayName +
                "-" +
                DateUtil.dategridFormatter(new Date()) +
                ".pptx";
              const url = URL.createObjectURL(blob);
              anchor.href = url;
              anchor.click();
              this.pptDownloads.shift();
              this.$pptDownloadCompletedSubject.next(true);

              if (!this.pptDownloads.length) {
                this.createLoaderResetObject(this.resetPptloader);
                this.loaderSubjects.pptLoader.next(this.resetPptloader);
                this.pptDownloads = [];
              }
            });
        } else {
          this.pptGeneratorService.downloadPPTFromFirebaseUrl(
            this.pptDownloads[0].pptUrls.formatToUrls[
              this.pptDownloads[0].format
            ]
          );
          this.pptDownloads.shift();
          this.$pptDownloadCompletedSubject.next(true);
          if (!this.pptDownloads.length) {
            this.createLoaderResetObject(this.resetPptloader);
            this.loaderSubjects.pptLoader.next(this.resetPptloader);
            this.pptDownloads = [];
          }
        }
      }
    }
  }

  downloadPPTBeta() {
    if (
      this.pptDownloads[0].pptData &&
      this.pptDownloads[0].pptData.items &&
      this.pptDownloads[0].pptData.items.length > 0
    ) {
      this.temporaryId = 0;
      let days;
      // let srNo = 1;
      // let subSr = 'a';
      let childrenLength;
      this.srNo = 1;
      this.subSr = "a";
      this.showChildren = false;
      // let showChildren = false;
      this.pptGenerationObjects = [];
      this.pptGeneratorService.instantiatePpt();
      this.pptGeneratorService.setLayout(
        this.setPptConfig(this.pptDownloads[0].format)
      );
      this.pptGeneratorService.setVendorInformation(
        this.pptEmailUrl,
        this.pptText,
        this.pptRedirectUrl
      );
      if (this.pptDownloads[0].format === "Vendor Specific") {
        this.pptGeneratorService.setSlideMaster(
          this.pptDownloads[0].vendorPptConfig
        );
      } else {
        this.pptGeneratorService.setSlideMaster(
          this.pptDownloads[0].pptData.plan.customer.pptConfig
        );
      }
      this.pptGeneratorService.setFirstPageLayout(
        this.pptDownloads[0].format === "Vendor Specific"
          ? this.pptDownloads[0].vendorPptConfig
          : this.pptDownloads[0].pptData.plan,
        this.pptDownloads[0].format
      );
      this.pptDownloads[0].pptData.items.forEach((element, index) => {
        const pptGenerationData = new PptGenerationData();
        of(element)
          .pipe(
            switchMap((element) => {
              return this.pptGeneratorService.setImageDataForPPT(
                element.images && element.images.length > 0
                  ? element.images[0].url
                  : this.pptGeneratorService.noImageUrl,
                pptGenerationData
              );
            })

            /* // Tried a different approach
            switchMap((element) => {
              if (element.images && element.images.length > 0) {
                return this.pptGeneratorService.setImgHeightAndWidthInPptData(
                  element.images[0].url ||
                    "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89",
                  pptGenerationData
                );
              } else {
                const noImageUrl =
                  "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89";
                return this.pptGeneratorService.setImgHeightAndWidthInPptData(
                  noImageUrl,
                  pptGenerationData
                );
              }
            }),
            switchMap((pptData: any) => {
              return this.pptGeneratorService.firebaseUrlToBlobUrl(
                pptData.data,
                pptGenerationData
              );
            }), 
            */

            /* // Original Code
            switchMap((element) => {
                if (element.images && element.images.length > 0) {
                  return this.pptGeneratorService.getBase64ImageFromURL(
                    element.images[0].url,
                    pptGenerationData
                  );
                } else {
                  const noImageUrl =
                    "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89";
                  return this.pptGeneratorService.getBase64ImageFromURL(
                    noImageUrl,
                    pptGenerationData
                  );
                }
              }),
              switchMap((pptData) => {
                return this.pptGeneratorService.dataURItoBLob(
                  pptData["data"],
                  pptData
                );
              }),
              switchMap((pptData) => {
                return this.pptGeneratorService.blobToFile(
                  pptData["data"],
                  pptData
                );
              }),
              switchMap((pptData) => {
                return this.pptGeneratorService.compressFile(
                  pptData["data"],
                  pptData
                );
              }),
              switchMap((pptData) => {
                return this.pptGeneratorService.getBase64FromFile(
                  pptData["data"],
                  pptData
                );
              }) */
          )
          .subscribe(
            (result) => {
              pptGenerationData.size = element.size;
              pptGenerationData.lightType =
                HoardingLightEnum[element.lightType];
              const numberOfDays = this.calculationService.calculateDays(
                element.itemStartDate,
                element.itemEndDate,
                true
              );
              if (numberOfDays) {
                days = numberOfDays;
              }
              pptGenerationData.rate = element.cost;

              pptGenerationData.numberOfDays =
                numberOfDays || !isNaN(numberOfDays) ? numberOfDays : days;

              pptGenerationData.monthlyRate = element.rate;
              pptGenerationData.availabilityDate =
                this.getAvailableDate(element);
              pptGenerationData.customId = element.customId;
              pptGenerationData.temporaryId = index + 1;
              pptGenerationData.hasChild = element.hasChild;
              pptGenerationData.childInventoriesLength =
                element.childInventories.length;
              pptGenerationData.mediaType = element.mediaType;
              pptGenerationData.location = element.location;
              pptGenerationData.city = element.city;
              pptGenerationData.quantity = element.quantity;
              // pptGenerationData.header = this.getDescription(pp);
              this.pptGenerationObjects.push(pptGenerationData);
              this.savePpt(
                this.pptDownloads[0].pptData.items,
                element,
                this.pptDownloads[0].format
              );
            },
            (error) => {
              this.notificationServcie.error(
                "PPT Download Error",
                "Please Refresh."
              );
              // return observableEmpty();
            }
          );
      });
    }
  }

  downloadPPTBetaOptimized() {
    if (
      this.pptDownloads[0].pptData &&
      this.pptDownloads[0].pptData.items &&
      this.pptDownloads[0].pptData.items.length > 0
    ) {
      this.temporaryId = 0;
      this.srNo = 1;
      this.subSr = "a";
      this.showChildren = false;
      let items = JSON.parse(
        JSON.stringify(this.pptDownloads[0].pptData.items)
      );
      this.chunkWiseList = [];
      this.processedChunkList = [];
      while (items.length) {
        this.chunkWiseList.push(
          items.splice(
            0,
            this.pptDownloads[0] && this.pptDownloads[0].chunkSize
              ? this.pptDownloads[0].chunkSize
              : 15
          )
        );
        this.processedChunkList.push([]);
      }
      this.processOptimizedPPT();
    }
  }

  processOptimizedPPT() {
    let days;
    let pptDownloadsData = JSON.parse(JSON.stringify(this.pptDownloads[0]));
    this.chunkWiseList.forEach((chunk, chunkIndex) => {
      let alreadyProcessedChunk = this.alreadyProcessedChunks.find(
        (data) => data.index == chunkIndex
      );
      if (!alreadyProcessedChunk) {
        this.alreadyProcessedChunks.push({
          index: chunkIndex,
          status: "started",
        });
      }
      let inprogress = this.alreadyProcessedChunks.some(
        (data) => data.status == "inprogress"
      );
      if (
        alreadyProcessedChunk && alreadyProcessedChunk.status === "completed"
          ? false
          : !inprogress
      ) {
        chunk.forEach((element, index) => {
          const pptGenerationData = new PptGenerationData();
          // this.totalProcessedChunk.push(element);
          of(element)
            .pipe(
              switchMap((element) => {
                let alreadyProcessedChunk = this.alreadyProcessedChunks.find(
                  (data) => data.index == chunkIndex
                );
                if (alreadyProcessedChunk) {
                  alreadyProcessedChunk.status = "inprogress";
                }
                return this.pptGeneratorService.setImageDataForPPT(
                  element.images &&
                    element.images.length > 0 &&
                    element.images[0].url
                    ? element.images[0].url
                    : null,
                  pptGenerationData
                );
              })
            )
            .subscribe(
              (result) => {
                pptGenerationData.size = element.size;
                pptGenerationData.lightType =
                  HoardingLightEnum[element.lightType];
                const numberOfDays = this.calculationService.calculateDays(
                  element.itemStartDate,
                  element.itemEndDate,
                  true
                );
                if (numberOfDays) {
                  days = numberOfDays;
                }
                pptGenerationData.rate = element.cost;

                pptGenerationData.numberOfDays =
                  numberOfDays || !isNaN(numberOfDays) ? numberOfDays : days;

                pptGenerationData.monthlyRate = element.rate;
                pptGenerationData.availabilityDate =
                  this.getAvailableDate(element);
                pptGenerationData.customId = element.customId;
                // pptGenerationData.temporaryId = index + 1;
                let chunkSize =
                  pptDownloadsData && pptDownloadsData.chunkSize
                    ? pptDownloadsData.chunkSize
                    : 15;
                pptGenerationData.temporaryId =
                  chunkIndex * chunkSize + index + 1;
                pptGenerationData.hasChild = element.hasChild;
                pptGenerationData.childInventoriesLength =
                  element.childInventories.length;
                pptGenerationData.mediaType = element.mediaType;
                pptGenerationData.location = element.location;
                pptGenerationData.city = element.city;
                pptGenerationData.quantity = element.quantity;
                // pptGenerationData.header = this.getDescription(pp);
                // this.pptGenerationObjects.push(pptGenerationData);
                this.processedChunkList[chunkIndex][index] = pptGenerationData;
                this.savePptOptimized(
                  pptDownloadsData,
                  this.chunkWiseList,
                  this.processedChunkList,
                  chunkIndex
                );
              },
              (error) => {
                this.notificationServcie.error(
                  "PPT Download Error",
                  "Please Refresh."
                );
              }
            );
        });
      }
    });
  }

  savePptOptimized(
    pptDownloadsData,
    chunkWiseList?,
    processedChunkList?,
    chunkIndex?
  ) {
    let originalItems = chunkWiseList[chunkIndex];
    let processedItems = processedChunkList[chunkIndex];
    let allProcessedItems = [];
    processedChunkList.forEach(
      (processedChunk) =>
        (allProcessedItems = [...allProcessedItems, ...processedChunk])
    );
    if (originalItems.length === Object.keys(processedItems).length) {
      let inprogress = this.alreadyProcessedChunks.find(
        (data) => data.index == chunkIndex
      );
      if (inprogress) {
        inprogress.status = "completed";
      }
      if (pptDownloadsData.pptData.items.length === allProcessedItems.length) {
        this.pptGenerationObjects = [];
        this.pptGeneratorService.instantiatePpt();
        this.pptGeneratorService.setLayout(
          this.setPptConfig(pptDownloadsData.format)
        );
        this.pptGeneratorService.setVendorInformation(
          this.pptEmailUrl,
          this.pptText,
          this.pptRedirectUrl
        );
        if (pptDownloadsData.format === "Vendor Specific") {
          this.pptGeneratorService.setSlideMaster(
            pptDownloadsData.vendorPptConfig
          );
        } else {
          this.pptGeneratorService.setSlideMaster(
            pptDownloadsData.pptData.plan.customer.pptConfig
          );
        }
        this.pptGeneratorService.setFirstPageLayout(
          pptDownloadsData.format === "Vendor Specific"
            ? pptDownloadsData.vendorPptConfig
            : pptDownloadsData.pptData.plan,
          pptDownloadsData.format
        );
        const pptDataArray = [];
        pptDownloadsData.pptData.items.forEach((item, index) => {
          pptDataArray.push(
            ...allProcessedItems.filter(
              (data) => data.temporaryId === index + 1
            )
          );
        });
        this.srNo = 1;
        this.subSr = "a";
        this.showChildren = false;
        this.childrenLength = 0;
        pptDataArray.forEach((data) => {
          if (
            data.quantity === 1 &&
            (data.lightType === HoardingLightEnum["VARIOUS"] ||
              (data.hasChild && !data.size))
          ) {
            this.childrenLength = data.childInventoriesLength;
            data.srno = this.srNo;
            this.currentSerialNumber = this.srNo;
            this.srNo++;
            this.showChildren = true;
            this.subSr = "a";
          } else if (!this.showChildren || this.childrenLength === 0) {
            data.srno = this.srNo;
            this.srNo++;
            this.showChildren = false;
          } else if (this.showChildren) {
            data.srno = this.currentSerialNumber.toString() + this.subSr;
            this.subSr = String.fromCharCode(this.subSr.charCodeAt(0) + 1);
            this.childrenLength--;
          }
          data.header = this.getDescription(data, pptDownloadsData.format);

          this.pptGeneratorService.setItemSlide(
            data,
            this.setPptConfig(pptDownloadsData.format)
          );
        });
        this.pptGeneratorService.addTermAndConditionSlide(
          this.planTermAndCondtions,
          pptDownloadsData.pptData.plan.employee,
          null,
          false,
          this.setPptConfig(pptDownloadsData.format)
        );
        this.pptGeneratorService.savePpt(
          pptDownloadsData.pptData.plan.displayName
        );
        this.setActivityLog(
          "PLAN_PPT_GENERATION_WEB",
          pptDownloadsData.pptData.plan.displayName,
          pptDownloadsData.pptData.plan.id,
          "PLAN"
        );
        this.alreadyProcessedChunks = [];
        // this.pptsDownloadInProgress.pop();
        this.pptGeneratorService.planPptDownloadComplete$.subscribe((res) => {
          if (res) {
            this.loaderSubjects.planPptLoader.next(true);
            this.pptDownloads.shift();
            this.$pptDownloadCompletedSubject.next(true);
            if (!this.pptDownloads.length) {
              this.createLoaderResetObject(this.resetPptloader);
              this.loaderSubjects.pptLoader.next(this.resetPptloader);
              this.pptDownloads = [];
            }
          }
        });
      } else {
        this.processOptimizedPPT();
      }
    }
  }

  // downloadPPTBetaCurrent() {
  //   if (
  //     this.pptDownloads[0].pptData &&
  //     this.pptDownloads[0].pptData.items &&
  //     this.pptDownloads[0].pptData.items.length > 0
  //   ) {
  //     this.temporaryId = 0;
  //     this.srNo = 1;
  //     this.subSr = "a";
  //     this.showChildren = false;
  //     let items = JSON.parse(
  //       JSON.stringify(this.pptDownloads[0].pptData.items)
  //     );
  //     this.chunkWiseList = [];
  //     this.processedChunkList = [];
  //     while (items.length) {
  //       this.chunkWiseList.push(
  //         items.splice(
  //           0,
  //           this.pptDownloads[0] && this.pptDownloads[0].chunkSize
  //             ? this.pptDownloads[0].chunkSize
  //             : 15
  //         )
  //       );
  //       this.processedChunkList.push([]);
  //     }
  //     this.processPPT();
  //   }
  // }

  // processPPT() {
  //   let days;
  //   let pptDownloadsData = JSON.parse(JSON.stringify(this.pptDownloads[0]));
  //   this.chunkWiseList.forEach((chunk, chunkIndex) => {
  //     let alreadyProcessedChunk = this.alreadyProcessedChunks.find(
  //       (data) => data.index == chunkIndex
  //     );
  //     if (!alreadyProcessedChunk) {
  //       this.alreadyProcessedChunks.push({
  //         index: chunkIndex,
  //         status: "started",
  //       });
  //     }
  //     let inprogress = this.alreadyProcessedChunks.some(
  //       (data) => data.status == "inprogress"
  //     );
  //     if (
  //       alreadyProcessedChunk && alreadyProcessedChunk.status === "completed"
  //         ? false
  //         : !inprogress
  //     ) {
  //       chunk.forEach((element, index) => {
  //         const pptGenerationData = new PptGenerationData();
  //         // this.totalProcessedChunk.push(element);
  //         of(element)
  //           .pipe(
  //             switchMap((element) => {
  //               let alreadyProcessedChunk = this.alreadyProcessedChunks.find(
  //                 (data) => data.index == chunkIndex
  //               );

  //               if (alreadyProcessedChunk) {
  //                 alreadyProcessedChunk.status = "inprogress";
  //               }
  //               if (element.images && element.images.length > 0) {
  //                 return this.pptGeneratorService.getBase64ImageFromURL(
  //                   element.images[0].url,
  //                   pptGenerationData
  //                 );
  //               } else {
  //                 const noImageUrl =
  //                   "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89";
  //                 return this.pptGeneratorService.getBase64ImageFromURL(
  //                   noImageUrl,
  //                   pptGenerationData
  //                 );
  //               }
  //             }),
  //             switchMap((pptData) => {
  //               return this.pptGeneratorService.dataURItoBLob(
  //                 pptData["data"],
  //                 pptData
  //               );
  //             }),
  //             switchMap((pptData) => {
  //               return this.pptGeneratorService.blobToFile(
  //                 pptData["data"],
  //                 pptData
  //               );
  //             }),
  //             switchMap((pptData) => {
  //               return this.pptGeneratorService.compressFile(
  //                 pptData["data"],
  //                 pptData
  //               );
  //             }),
  //             switchMap((pptData) => {
  //               return this.pptGeneratorService.getBase64FromFile(
  //                 pptData["data"],
  //                 pptData
  //               );
  //             })
  //           )
  //           .subscribe(
  //             (result) => {
  //               pptGenerationData.size = element.size;
  //               pptGenerationData.lightType =
  //                 HoardingLightEnum[element.lightType];
  //               const numberOfDays = this.calculationService.calculateDays(
  //                 element.itemStartDate,
  //                 element.itemEndDate,
  //                 true
  //               );
  //               if (numberOfDays) {
  //                 days = numberOfDays;
  //               }
  //               pptGenerationData.rate = element.cost;

  //               pptGenerationData.numberOfDays =
  //                 numberOfDays || !isNaN(numberOfDays) ? numberOfDays : days;

  //               pptGenerationData.monthlyRate = element.rate;
  //               pptGenerationData.availabilityDate =
  //                 this.getAvailableDate(element);
  //               pptGenerationData.customId = element.customId;
  //               // pptGenerationData.temporaryId = index + 1;
  //               let chunkSize =
  //                 pptDownloadsData && pptDownloadsData.chunkSize
  //                   ? pptDownloadsData.chunkSize
  //                   : 15;
  //               pptGenerationData.temporaryId =
  //                 chunkIndex * chunkSize + index + 1;
  //               pptGenerationData.hasChild = element.hasChild;
  //               pptGenerationData.childInventoriesLength =
  //                 element.childInventories.length;
  //               pptGenerationData.mediaType = element.mediaType;
  //               pptGenerationData.location = element.location;
  //               pptGenerationData.city = element.city;
  //               pptGenerationData.quantity = element.quantity;
  //               // pptGenerationData.header = this.getDescription(pp);
  //               // this.pptGenerationObjects.push(pptGenerationData);
  //               this.processedChunkList[chunkIndex][index] = pptGenerationData;
  //               this.savePptCurrent(
  //                 pptDownloadsData,
  //                 this.chunkWiseList,
  //                 this.processedChunkList,
  //                 chunkIndex
  //               );
  //             },
  //             (error) => {
  //               this.notificationServcie.error(
  //                 "PPT Download Error",
  //                 "Please Refresh."
  //               );
  //             }
  //           );
  //       });
  //     }
  //   });
  // }

  generateAndSendPPT() {
    if (this.pptDownloads[0].pptData) {
      if (this.pptDownloads[0].customIds.length === 0) {
        this.notificationServcie.error(
          "PPT Download Error",
          "Please Try Again!"
        );
      } else {
        this.planService
          .downloadFile(
            this.pptDownloads[0].pptData.id,
            this.pptDownloads[0].fileType,
            this.pptDownloads[0].ids,
            null,
            this.pptDownloads[0].format
          )
          .subscribe((response: any) => {
            if (response.name !== "HttpErrorResponse") {
              this.notificationServcie.success(
                NotificationsMessages.PPT_GENERATED_AND_SEND_SUCCESSFULLY,
                NotificationsMessages.PPT_GENERATED_AND_SEND
              );
            }

            this.pptDownloads.shift();
            this.$pptDownloadCompletedSubject.next(true);
            this.loaderSubjects.planPptLoader.next(true);

            if (!this.pptDownloads.length) {
              this.createLoaderResetObject(this.resetPptloader);
              this.loaderSubjects.pptLoader.next(this.resetPptloader);
              this.pptDownloads = [];
            }
          });
      }
    }
  }

  // savePptCurrent(
  //   pptDownloadsData,
  //   chunkWiseList?,
  //   processedChunkList?,
  //   chunkIndex?
  // ) {
  //   let originalItems = chunkWiseList[chunkIndex];
  //   let processedItems = processedChunkList[chunkIndex];
  //   let allProcessedItems = [];
  //   processedChunkList.forEach(
  //     (processedChunk) =>
  //       (allProcessedItems = [...allProcessedItems, ...processedChunk])
  //   );
  //   if (originalItems.length === Object.keys(processedItems).length) {
  //     let inprogress = this.alreadyProcessedChunks.find(
  //       (data) => data.index == chunkIndex
  //     );
  //     if (inprogress) {
  //       inprogress.status = "completed";
  //     }
  //     if (pptDownloadsData.pptData.items.length === allProcessedItems.length) {
  //       this.pptGenerationObjects = [];
  //       this.pptGeneratorService.instantiatePpt();
  //       this.pptGeneratorService.setLayout(
  //         this.setPptConfig(pptDownloadsData.format)
  //       );
  //       this.pptGeneratorService.setVendorInformation(
  //         this.pptEmailUrl,
  //         this.pptText,
  //         this.pptRedirectUrl
  //       );
  //       if (pptDownloadsData.format === "Vendor Specific") {
  //         this.pptGeneratorService.setSlideMaster(
  //           pptDownloadsData.vendorPptConfig
  //         );
  //       } else {
  //         this.pptGeneratorService.setSlideMaster(
  //           pptDownloadsData.pptData.plan.customer.pptConfig
  //         );
  //       }
  //       this.pptGeneratorService.setFirstPageLayout(
  //         pptDownloadsData.format === "Vendor Specific"
  //           ? pptDownloadsData.vendorPptConfig
  //           : pptDownloadsData.pptData.plan,
  //         pptDownloadsData.format
  //       );
  //       const pptDataArray = [];
  //       pptDownloadsData.pptData.items.forEach((item, index) => {
  //         pptDataArray.push(
  //           ...allProcessedItems.filter(
  //             (data) => data.temporaryId === index + 1
  //           )
  //         );
  //       });
  //       this.srNo = 1;
  //       this.subSr = "a";
  //       this.showChildren = false;
  //       this.childrenLength = 0;
  //       pptDataArray.forEach((data) => {
  //         if (
  //           data.quantity === 1 &&
  //           (data.lightType === HoardingLightEnum["VARIOUS"] ||
  //             (data.hasChild && !data.size))
  //         ) {
  //           this.childrenLength = data.childInventoriesLength;
  //           data.srno = this.srNo;
  //           this.currentSerialNumber = this.srNo;
  //           this.srNo++;
  //           this.showChildren = true;
  //           this.subSr = "a";
  //         } else if (!this.showChildren || this.childrenLength === 0) {
  //           data.srno = this.srNo;
  //           this.srNo++;
  //           this.showChildren = false;
  //         } else if (this.showChildren) {
  //           data.srno = this.currentSerialNumber.toString() + this.subSr;
  //           this.subSr = String.fromCharCode(this.subSr.charCodeAt(0) + 1);
  //           this.childrenLength--;
  //         }
  //         data.header = this.getDescription(data, pptDownloadsData.format);

  //         this.pptGeneratorService.setItemSlide(
  //           data,
  //           this.setPptConfig(pptDownloadsData.format)
  //         );
  //       });
  //       this.pptGeneratorService.addTermAndConditionSlide(
  //         this.planTermAndCondtions,
  //         pptDownloadsData.pptData.plan.employee,
  //         null,
  //         false,
  //         this.setPptConfig(pptDownloadsData.format)
  //       );
  //       this.pptGeneratorService.savePpt(
  //         pptDownloadsData.pptData.plan.displayName
  //       );
  //       this.setActivityLog(
  //         "PLAN_PPT_GENERATION_WEB",
  //         pptDownloadsData.pptData.plan.displayName,
  //         pptDownloadsData.pptData.plan.id,
  //         "PLAN"
  //       );
  //       this.alreadyProcessedChunks = [];
  //       // this.pptsDownloadInProgress.pop();
  //       this.loaderSubjects.planPptLoader.next(true);
  //       this.pptDownloads.shift();
  //       this.$pptDownloadCompletedSubject.next(true);
  //       if (!this.pptDownloads.length) {
  //         this.createLoaderResetObject(this.resetPptloader);
  //         this.loaderSubjects.pptLoader.next(this.resetPptloader);
  //         this.pptDownloads = [];
  //       }
  //     } else {
  //       this.processPPT();
  //     }
  //   }
  // }

  savePpt(items, element, format?) {
    if (items.length === this.pptGenerationObjects.length) {
      const pptDataArray = [];
      items.forEach((item, index) => {
        pptDataArray.push(
          ...this.pptGenerationObjects.filter(
            (data) => data.temporaryId === index + 1
          )
        );
      });
      this.srNo = 1;
      this.subSr = "a";
      this.showChildren = false;
      this.childrenLength = 0;
      pptDataArray.forEach((data) => {
        if (
          data.quantity === 1 &&
          (data.lightType === HoardingLightEnum["VARIOUS"] ||
            (data.hasChild && !data.size))
        ) {
          this.childrenLength = data.childInventoriesLength;
          data.srno = this.srNo;
          this.currentSerialNumber = this.srNo;
          this.srNo++;
          this.showChildren = true;
          this.subSr = "a";
        } else if (!this.showChildren || this.childrenLength === 0) {
          data.srno = this.srNo;
          this.srNo++;
          this.showChildren = false;
        } else if (this.showChildren) {
          data.srno = this.currentSerialNumber.toString() + this.subSr;
          this.subSr = String.fromCharCode(this.subSr.charCodeAt(0) + 1);
          this.childrenLength--;
        }
        data.header = this.getDescription(data, format);

        this.pptGeneratorService.setItemSlide(
          data,
          this.setPptConfig(this.pptDownloads[0].format)
        );
      });
      this.pptGeneratorService.addTermAndConditionSlide(
        this.planTermAndCondtions,
        this.pptDownloads[0].pptData.plan.employee,
        null,
        false,
        this.setPptConfig(this.pptDownloads[0].format)
      );
      this.pptGeneratorService.savePpt(
        this.pptDownloads[0].pptData.plan.displayName
      );
      this.setActivityLog(
        "PLAN_PPT_GENERATION_WEB",
        this.pptDownloads[0].pptData.plan.displayName,
        this.pptDownloads[0].pptData.plan.id,
        "PLAN"
      );
      this.pptGeneratorService.planPptDownloadComplete$.subscribe((res) => {
        if (res) {
          this.loaderSubjects.planPptLoader.next(true);
          this.pptDownloads.shift();
          this.$pptDownloadCompletedSubject.next(true);
          if (!this.pptDownloads.length) {
            this.createLoaderResetObject(this.resetPptloader);
            this.loaderSubjects.pptLoader.next(this.resetPptloader);
            this.pptDownloads = [];
          }
        }
      });
    }
  }

  getDescription(data, format?) {
    const hid = data.customId ? data.customId : "";
    const city = data.city ? data.city : "";
    const location = data.location ? data.location : "";
    const mediaType =
      JSON.parse(localStorage.getItem("mediaTypes")) &&
      Object.keys(JSON.parse(localStorage.getItem("mediaTypes"))).length
        ? JSON.parse(localStorage.getItem("mediaTypes"))[data.mediaType]
        : data.mediaType.charAt(0).toUpperCase() +
          data.mediaType.substr(1).toLowerCase();
    const size = data.size ? data.size : "";
    const lightType = data.lightType ? data.lightType : "";
    const quantity =
      data.mediaType === "KIOSK" || data.mediaType === "PILLAR"
        ? data.quantity
        : data.quantity > 1
        ? data.quantity
        : 0;

    switch (format) {
      case "Full Detail": {
        return (
          data.srno +
          ") " +
          (hid && hid !== "" && hid !== null ? hid : "") +
          " (" +
          mediaType +
          (quantity ? ", Qty- " + quantity : "") +
          ") - " +
          (city && city !== "" && city !== null ? city : "") +
          (location && location !== "" && location !== null
            ? ", " + location
            : "")
        );
      }
      case "Less Detail": {
        return (
          data.srno +
          ") " +
          (hid && hid !== "" && hid !== null ? hid : "") +
          " (" +
          mediaType +
          (quantity ? ", Qty- " + quantity : "") +
          ") - " +
          (city && city !== "" && city !== null ? city : "") +
          (location && location !== "" && location !== null
            ? ", " + location
            : "") +
          (size && size !== "" && size !== null ? " - " + size : "") +
          (lightType && lightType !== "" && lightType !== null
            ? " - " + lightType
            : "")
        );
      }
      case "Customer Configured":
      case "Vendor Specific": {
        return (
          data.srno +
          ") " +
          (hid && hid !== "" && hid !== null ? hid : "") +
          " (" +
          mediaType +
          (quantity ? ", Qty- " + quantity : "") +
          ") - " +
          (city && city !== "" && city !== null ? city : "") +
          (location && location !== "" && location !== null
            ? ", " + location
            : "") +
          ((this.pptDownloads[0].vendorPptConfig &&
            this.pptDownloads[0].vendorPptConfig.middleSlideFormat !==
              "FULL_DETAILS") ||
          (this.pptDownloads[0].pptData.plan.customer.pptConfig &&
            this.pptDownloads[0].pptData.plan.customer.pptConfig
              .middleSlideFormat !== "FULL_DETAILS")
            ? (size && size !== "" && size !== null ? " - " + size : "") +
              (lightType && lightType !== "" && lightType !== null
                ? " - " + lightType
                : "")
            : "")
        );
      }
    }
  }

  getAvailableDate(item) {
    switch (item.status) {
      case "AVAILABLE": {
        return "Available Now";
      }

      case "BLOCKED": {
        // var obj = this.findMaxDateObject(item.statusQueues, 'blockedEndDate');
        // if (this.pptloader.pptData.plan.id === obj.id) {
        //     return 'Available Now'
        // } else {
        //     return obj.status;
        // }
        let remainingObj = [];
        if (item.statusQueues && item.statusQueues.length > 0) {
          if (
            item.statusQueues.length === 1 &&
            item.statusQueues[0].id === this.pptloader.pptData.plan.id
          ) {
            return "Available Now";
          } else if (
            item.statusQueues.length === 1 &&
            item.statusQueues[0].id != this.pptloader.pptData.plan.id
          ) {
            return item.statusQueues[0].status;
          } else {
            item.statusQueues.forEach((statusQueue) => {
              if (statusQueue.id != this.pptloader.pptData.plan.id) {
                remainingObj.push(statusQueue);
              }
            });
            var obj = this.findMaxDateObject(remainingObj, "blockedEndDate");
            // if(obj && obj.length > 0) {
            return obj.status;
            // } else {
            //     return '';
            // }
            // return obj.status;
          }
        } else {
          return "";
        }
      }

      case "NOT_AVAILABLE": {
        // var planBlocked = item.statusQueues.filter(item => item.module === 'Plan');
        if (item.statusQueues && item.statusQueues.length) {
          var inCampaign = item.statusQueues.filter(
            (item) => item.module === "Campaign"
          );

          // var inPlanMaxBlockDateObj = this.findMaxDateObject(planBlocked, 'blockedEndDate');
          var inCampaignMaxDateObj = this.findMaxDateObject(
            inCampaign,
            "campaignEndDate"
          );

          var availableStatus =
            String(
              DateUtil.formatDate(
                new Date(
                  new Date(inCampaignMaxDateObj.campaignEndDate).getTime() +
                    86400000
                )
              )
            ) + this.getPlanStatus(item.statusQueues);
          return availableStatus;
        }
        return "-";
      }
    }
  }

  getPlanStatus(statusQueues) {
    // if (obj) {
    //     if (this.pptloader.pptData.plan.id === obj.id) {
    //         return ''
    //     } else {
    //         return ' / ' + obj.status;
    //     }
    // } else {
    //     return '';
    // }
    var planBlocked = statusQueues.filter((item) => item.module === "Plan");
    let remainingObj = [];
    if (planBlocked && planBlocked.length > 0) {
      if (
        planBlocked[0].id === this.pptloader.pptData.plan.id &&
        planBlocked.length === 1
      ) {
        return "";
      } else if (
        planBlocked[0].id !== this.pptloader.pptData.plan.id &&
        planBlocked.length === 1
      ) {
        return " / " + planBlocked[0].status;
      } else if (planBlocked.length > 1) {
        planBlocked.forEach((statusQueue) => {
          if (statusQueue.id != this.pptloader.pptData.plan.id) {
            remainingObj.push(statusQueue);
          }
        });
        let obj = this.findMaxDateObject(remainingObj, "blockEndDate");
        return " / " + obj.status;
      }
    } else {
      return "";
    }
  }

  findMaxDateObject(items, field) {
    return items.reduce((acc, obj) => {
      var maxDate = acc[field];
      var toCompareDate = obj[field];
      if (toCompareDate > maxDate) {
        acc = obj;
      }
      return acc;
    }, items[0]);
  }

  downloadXls() {
    if (this.xlsLoader.data) {
      this.planService
        .downloadFile(
          this.xlsLoader.data.id,
          this.xlsLoader.fileType,
          this.xlsLoader.ids
        )
        .subscribe((response: any) => {
          if (this.xlsLoader.fileType === "XLS") {
            let blob;
            const anchor = document.createElement("a");
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            anchor.download =
              this.xlsLoader.data.displayName +
              "-" +
              DateUtil.dategridFormatter(new Date()) +
              ".xlsx";
            const url = URL.createObjectURL(blob);
            anchor.href = url;
            anchor.click();
          } else {
            this.notificationServcie.success(
              NotificationsMessages.XLSX_GENERATED_AND_SEND_SUCCESSFULLY,
              NotificationsMessages.XLSX_GENERATED_AND_SEND
            );
          }
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadCampaignXls() {
    if (this.xlsLoader.data) {
      this.campaignService
        .downloadFile(
          this.xlsLoader.data.id,
          this.xlsLoader.fileType,
          this.xlsLoader.subModule,
          this.xlsLoader.ids
        )
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = this.xlsLoader.data.displayName + ".xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadCampaignTrackerXls() {
    if (this.xlsLoader.data) {
      this.campaignService
        .downloadCampaignTrackerFile(
          this.xlsLoader.data.id,
          this.xlsLoader.fileType,
          this.tenantId,
          this.xlsLoader.subModule,
          this.xlsLoader.customIds
        )
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = this.xlsLoader.data.displayName + ".xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadPendingUnbilledXls() {
    if (this.xlsLoader.data) {
      this.pendingUnbilledUIService
        .downloadFile("XLSX", this.xlsLoader.data)
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "Pending-Unbilled.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;

            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  downloadSuperbillListXls() {
    if (this.xlsLoader.searchEvent) {
      this.superBillsService
        .downloadSuperbillListXls("XLS", this.xlsLoader.searchEvent)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "SuperbillList.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadInvoiceXls() {
    if (this.xlsLoader.searchEvent) {
      this.invoiceChartService
        .downloadFile("XLSX", this.xlsLoader.searchEvent)
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "Invoices.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;

            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  downloadInvoiceTallyXls() {
    if (this.xlsLoader.searchEvent) {
      this.invoiceChartService
        .downloadFile("XLSX", this.xlsLoader.searchEvent, "tally")
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "TallyInvoices.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;

            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  downloadMigratedInvoiceXls() {
    if (this.xlsLoader.searchEvent) {
      this.invoiceChartService
        .downloadFile("XLSX", this.xlsLoader.searchEvent, "migratedInvoice")
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "Migrated_Invoice.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;

            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  downloadCreditNoteXls() {
    if (this.xlsLoader.searchEvent) {
      this.invoiceChartService
        .downloadFile("XLSX", this.xlsLoader.searchEvent, "creditNote")
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "Credit_Note.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;

            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  downloadReservedInvoiceXls() {
    if (this.xlsLoader.searchEvent) {
      this.invoiceChartService
        .downloadFile("XLSX", this.xlsLoader.searchEvent, "reservedInvoice")
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "Reserved_Invoices.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;

            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  downloadReservedCNXls() {
    if (this.xlsLoader.searchEvent) {
      this.invoiceChartService
        .downloadFile("XLSX", this.xlsLoader.searchEvent, "reservedCN")
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "Reserved_Credit_Note.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;

            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  downloadFlexItemsXls() {
    if (this.xlsLoader.searchEvent) {
      this.hoardingOperationService
        .downloadFlexXls("XLS", this.xlsLoader.searchEvent)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "FlexItems.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.flexItemsXlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadMountingItemsXls() {
    if (this.xlsLoader.searchEvent) {
      this.hoardingOperationService
        .downloadMountingXls("XLS", this.xlsLoader.searchEvent)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "MountItems.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.mountingItemsXlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadMonitoringItemsXls() {
    if (this.xlsLoader.searchEvent) {
      this.hoardingOperationService
        .downloadMonitoringXls("XLS", this.xlsLoader.searchEvent)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "MonitoringItems.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.monitoringItemsXlsLoader.next(
            this.resetXlsLoader
          );
        });
    }
  }

  downloadUnmountingItemsXls() {
    if (this.xlsLoader.searchEvent) {
      this.hoardingOperationService
        .downloadUnmountingXls("XLS", this.xlsLoader.searchEvent)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "UnmountItems.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.unmountingItemsXlsLoader.next(
            this.resetXlsLoader
          );
        });
    }
  }

  downloadImages(object) {
    // if (this.photoLoader.items.length > 0) {
    //     fileUtils.createFileBlobs(this.photoLoader.items, this.photoLoader.name);
    // }
    if (object.items.length > 0) {
      fileUtils.createFileBlobs(object.items, object.name);
    }
  }

  createLoaderResetObject(object) {
    object.fileType = null;
    object.text = null;
    object.isLoading = false;
    object.data = null;
    object.pptData = null;
  }

  createPhotoLoaderResetObject(object) {
    object.items = null;
    object.isLoading = false;
    object.text = null;
    object.name = null;
    // object.isNoImage = false;
  }

  createResetObject(object) {
    object.text = null;
    object.data = null;
  }

  setTitleSubscription() {
    this.appTitleSubscription = this.appTitle.title$.subscribe((appTitle) => {
      this.title = appTitle;
    });
  }

  moveToHomepage() {
    this.router.navigate(["/"]);
  }

  showUserList() {}

  logout() {
    this.image = null;
    this.auth.logout();
  }

  viewProfile() {
    this.router.navigate(["/profile"]);
  }

  getRoles() {
    if (this.userProfile && this.userProfile.app_metadata) {
      for (
        let i = 0;
        i < this.userProfile.app_metadata.authorization.roles.length;
        i++
      ) {
        this.userProfile.app_metadata.authorization.roles[i] =
          this.userProfile.app_metadata.authorization.roles[i]
            .charAt(0)
            .toUpperCase() +
          this.userProfile.app_metadata.authorization.roles[i].slice(1);
      }
      return this.userProfile.app_metadata.authorization.roles.join(", ");
    }
  }

  showHelpDialog() {
    this.display = true;
  }

  showSuggestionDialog() {
    this.displaySuggestionDialog = true;
  }
  closeSuggestionDialog() {
    this.displaySuggestionDialog = false;
  }

  /**
   * @description download campaign ppt from frontend
   * @author Divya Sachan
   * @date 2019-10-18
   * @memberof AppTopBar
   */
  downloadCampaignPpt() {
    if (this.pptloader.pptData) {
      const noImageUrl =
        "https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89";
      let type: any;
      if (
        this.pptloader.pptData &&
        this.pptloader.pptData.items &&
        this.pptloader.pptData.items.length > 0
      ) {
        this.pptGenerationObjects = [];
        this.pptGeneratorService.instantiatePpt();
        this.pptGeneratorService.setLayout();
        this.pptGeneratorService.setVendorInformation(
          this.pptEmailUrl,
          this.pptText,
          this.pptRedirectUrl,
          this.portalBaseUrl
        );
        this.pptGeneratorService.setSlideMaster();
        this.pptGeneratorService.setFirstPageLayout(
          this.pptloader.pptData.campaign,
          this.pptloader.pptData.tabIndex === 0 ? PptFormatEnum.LESS_DETAIL : ""
        );
        this.pptloader.pptData.items.forEach((element, index) => {
          var pptGenerationData = new PptGenerationData();
          of(element)
            .pipe(
              switchMap((element) => {
                if (this.pptloader.pptData.tabIndex === 0) {
                  // for site tab
                  if (element && element.images && element.images.length > 0) {
                    return this.pptGeneratorService.getBase64ImageFromURL(
                      element.images[0].url,
                      pptGenerationData
                    );
                  } else {
                    return this.pptGeneratorService.getBase64ImageFromURL(
                      noImageUrl,
                      pptGenerationData
                    );
                  }
                } else if (this.pptloader.pptData.tabIndex === 1) {
                  // for mounting tab
                  if (
                    element &&
                    element.mountingItems.images &&
                    element.mountingItems.images.length > 0
                  ) {
                    return this.pptGeneratorService.getBase64ImageFromURL(
                      element.mountingItems.images[0].url,
                      pptGenerationData
                    );
                  }
                } else if (this.pptloader.pptData.tabIndex === 2) {
                  // for monitoring tab
                  if (
                    element &&
                    element.monitoringItems.images &&
                    element.monitoringItems.images.length > 0
                  ) {
                    return this.pptGeneratorService.getBase64ImageFromURL(
                      element.monitoringItems.images[0].url,
                      pptGenerationData
                    );
                  }
                } else if (this.pptloader.pptData.tabIndex === 3) {
                  // for unmounting tab
                  if (
                    element &&
                    element.unmountingItems.images &&
                    element.unmountingItems.images.length > 0
                  ) {
                    return this.pptGeneratorService.getBase64ImageFromURL(
                      element.unmountingItems.images[0].url,
                      pptGenerationData
                    );
                  }
                }
              }),
              switchMap((pptData) => {
                return this.pptGeneratorService.dataURItoBLob(
                  pptData["data"],
                  pptData
                );
              }),
              switchMap((pptData) => {
                return this.pptGeneratorService.blobToFile(
                  pptData["data"],
                  pptData
                );
              }),
              switchMap((pptData) => {
                return this.pptGeneratorService.compressFile(
                  pptData["data"],
                  pptData
                );
              }),
              switchMap((pptData) => {
                return this.pptGeneratorService.getBase64FromFile(
                  pptData["data"],
                  pptData
                );
              })
            )
            .subscribe(
              (result) => {
                pptGenerationData.srno = index + 1;
                pptGenerationData.size = this.pptloader.pptData.items[index]
                  .size
                  ? this.pptloader.pptData.items[index].size
                  : "-";
                pptGenerationData.lightType =
                  HoardingLightEnum[
                    this.pptloader.pptData.items[index].lightType
                  ];
                pptGenerationData.availabilityDate =
                  this.pptloader.pptData.items[index].itemEndDate;
                pptGenerationData.operatedDate = this.getOperatedDate(
                  this.pptloader.pptData.items[index],
                  this.pptloader.pptData.tabIndex
                );
                pptGenerationData.customId =
                  this.pptloader.pptData.items[index].customId;
                pptGenerationData.quantity =
                  this.pptloader.pptData.items[index].quantity;
                // pptGenerationData.imageType = elementImages[index].imageType;
                type = this.getImageType(
                  this.pptloader.pptData.items[index],
                  this.pptloader.pptData.tabIndex
                );
                pptGenerationData.imageType = type.imageType;
                pptGenerationData.mode = type.mode;
                pptGenerationData.mediaType =
                  element.mediaType.charAt(0).toUpperCase() +
                  element.mediaType.substr(1).toLowerCase();
                pptGenerationData.header = this.getDescriptionheaderforCampaign(
                  this.pptloader.pptData.items[index],
                  pptGenerationData,
                  this.pptloader.pptData.tabIndex
                );
                this.pptGenerationObjects.push(pptGenerationData);
                this.saveCampaignPpt(
                  this.pptloader.pptData.items,
                  this.pptloader.pptData.tabIndex
                );
              },
              (error) => {
                this.pptloader.text = null;
                this.pptloader.isLoading = false;
                this.notificationServcie.error("Error", "Please Refresh.");
              }
            );
        });
      } else {
        this.pptloader.isLoading = false;
        this.pptloader.text = null;
        let notification = this.setNotificationTabWise();
        this.notificationServcie.info(notification, "PPT Download");
      }
    }
  }

  // Get image type for campaign item
  getImageType(data, tabIndex) {
    let imageType = "";
    let mode = "";
    switch (tabIndex) {
      case 0: {
        // site tab
        imageType = "";
        mode = "";
        break;
      }
      case 1: {
        // mounting tab
        imageType =
          data.mountingItems.images && data.mountingItems.images.length > 0
            ? "(" + ImageTypeEnum[data.mountingItems.images[0].imageType] + ")"
            : "";
        mode =
          data.mountingItems.creativeName !== "Default"
            ? "(" + data.mountingItems.creativeName + ")"
            : "";
        break;
      }
      case 2: {
        // monitoring tab
        imageType =
          data.monitoringItems.images && data.monitoringItems.images.length > 0
            ? "(" +
              ImageTypeEnum[data.monitoringItems.images[0].imageType] +
              ")"
            : "";
        mode =
          data.monitoringItems.mode !== undefined
            ? "(" + data.monitoringItems.mode + ")"
            : "";
        break;
      }
      case 3: {
        // unmounting tab
        imageType =
          data.unmountingItems.images && data.unmountingItems.images.length > 0
            ? "(" +
              ImageTypeEnum[data.unmountingItems.images[0].imageType] +
              ")"
            : "";
        mode =
          data.unmountingItems.creativeName !== "Default"
            ? "(" + data.unmountingItems.creativeName + ")"
            : "";
        break;
      }
    }
    return { imageType: imageType, mode: mode };
  }

  //get Description header for campaign item
  getDescriptionheaderforCampaign(data, pptData, tabIndex) {
    const hid = data.customId ? data.customId : "";
    const city = data.city ? data.city : "";
    const location = data.location ? data.location : "";
    let imageType = this.getImageType(data, tabIndex).imageType;
    const mediaType =
      JSON.parse(localStorage.getItem("mediaTypes")) &&
      Object.keys(JSON.parse(localStorage.getItem("mediaTypes"))).length
        ? JSON.parse(localStorage.getItem("mediaTypes"))[data.mediaType]
        : data.mediaType.charAt(0).toUpperCase() +
          data.mediaType.substr(1).toLowerCase();
    const size = data.size ? data.size : "";
    const lightType = data.lightType ? data.lightType : "";
    const quantity =
      data.mediaType === "KIOSK" || data.mediaType === "PILLAR"
        ? data.quantity
        : data.quantity > 1
        ? data.quantity
        : 0;
    return (
      pptData.srno +
      ") " +
      (hid && hid !== "" && hid !== null ? hid : "") +
      " (" +
      mediaType +
      (quantity ? ", Qty- " + quantity : "") +
      ") - " +
      (city && city !== "" && city !== null ? city : "") +
      (location && location !== "" && location !== null
        ? ", " + location
        : "") +
      (tabIndex === 0
        ? (size && size !== "" && size !== null ? " - " + size : "") +
          (lightType && lightType !== "" && lightType !== null
            ? " - " + HoardingLightEnum[lightType]
            : "")
        : imageType)
    );
    // return pptData.srno + ') ' + hid + ' (' + mediaType + ') - ' + (city && city !== '' && city !== null ? ' - ' + city : '') + (location && location !== '' && location !== null ? ', ' + location : '') + imageType;
  }

  // get startdate for sites tab, mounted date for mounted item, monitoring date for monitored item and unmounting date for unmounted item.
  getOperatedDate(element, tabIndex) {
    let operatedDate;
    switch (tabIndex) {
      case 0: {
        //site tab
        operatedDate = element.itemStartDate;
        break;
      }
      case 1: {
        // mounting tab
        operatedDate = element.mountingItems.date;
        break;
      }
      case 2: {
        // monitoring tab
        operatedDate = element.monitoringItems.monitoringDate;
        break;
      }
      case 3: {
        // unmounting tab
        operatedDate = element.unmountingItems.date;
        break;
      }
    }
    return operatedDate;
  }

  // saving ppt for campaign items
  saveCampaignPpt(items, tabIndex) {
    if (items.length === this.pptGenerationObjects.length) {
      var pptDataArray = [];
      items.forEach((item, index) => {
        // pptDataArray.push(...this.pptGenerationObjects.filter(data => data.))
        pptDataArray.push(
          ...this.pptGenerationObjects.filter((data) => data.srno === index + 1)
        ); // filtering data to make the items the come in order in ppt
      });
      pptDataArray.forEach((data) => {
        this.pptGeneratorService.setItemSlideForCampaign(data, tabIndex); // creating slide individual campaign item
      });
      this.pptGeneratorService.addTermAndConditionSlide(
        this.campaignTermAndConditions,
        this.pptloader.pptData.campaign.employee,
        this.pptloader.pptData.campaign.id,
        true
      ); // adding term and condition slide
      this.pptGeneratorService.savePpt(
        this.pptloader.pptData.campaign.displayName
      );
      this.setActivityLog(
        "CAMPAIGN_PPT_GENERATION_WEB",
        this.pptloader.pptData.campaign.displayName,
        this.pptloader.pptData.campaign.id,
        "CAMPAIGN"
      );

      this.pptGeneratorService.campaignPptDownloadComplete$.subscribe((res) => {
        if (res) {
          // this.pptsDownloadInProgress.pop();
          this.campaignPptDownloads.shift();
          this.$pptDownloadCompletedSubject.next(true);
          if (!this.campaignPptDownloads.length) {
            this.createLoaderResetObject(this.resetPptloader);
            this.loaderSubjects.campaignPptLoader.next(this.resetPptloader);
            this.campaignPptDownloads = [];
          }
        }
      });
    }
  }

  setNotificationTabWise() {
    let notificationTabWise = "";
    if (this.pptloader.pptData.tabIndex === 1) {
      notificationTabWise = this.setNotificationAccordingToItemType("mounted");
    } else if (this.pptloader.pptData.tabIndex === 2) {
      notificationTabWise =
        this.setNotificationAccordingToItemType("monitored");
    } else if (this.pptloader.pptData.tabIndex === 3) {
      notificationTabWise =
        this.setNotificationAccordingToItemType("unmounted");
    } else {
      notificationTabWise = this.setNotificationAccordingToItemType("");
    }
    return notificationTabWise;
  }

  setNotificationAccordingToItemType(itemType) {
    let notification = "";
    if (this.pptloader.pptData.itemCount === 0) {
      notification = "There are no " + itemType + " items to be exported.";
    } else if (
      this.pptloader.pptData.itemCount > 0 &&
      this.pptloader.pptData.items.length === 0
    ) {
      notification =
        "There are no " + itemType + " items with images to download PPT.";
    }
    return notification;
  }

  setTabwiseNotification() {
    if (this.pptloader.pptData.tabIndex === 1) {
      return "mounted";
    } else if (this.pptloader.pptData.tabIndex === 2) {
      return "monitored";
    } else if (this.pptloader.pptData.tabIndex === 3) {
      return "unmounted";
    }
  }

  getVendorInformation() {
    this.isLoading = true;
    this.tenantId = localStorage.getItem("id");
    this.vendorService.get(null, this.tenantId).subscribe((response) => {
      if (response) {
        localStorage.setItem("appTitle", response["name"]);
        localStorage.setItem(
          "subscriptionActive",
          !response.subscriptionDetails
            ? response.subscriptionDetails
            : response.subscriptionDetails.active
        );
        this.packageService.$subscriptionActive.next(
          localStorage.getItem("subscriptionActive")
        );
        localStorage.setItem(
          "subscribedPackage",
          !response.subscriptionDetails
            ? response.subscriptionDetails
            : JSON.stringify(response.subscriptionDetails.activePackage)
        );
        !utils.getAppTitle()
          ? this.pageTitle.getTitle().includes("-")
            ? this.pageTitle.setTitle(this.pageTitle.getTitle().split(" -")[0])
            : this.pageTitle.setTitle(this.pageTitle.getTitle())
          : this.pageTitle.getTitle().includes("-")
          ? this.pageTitle.setTitle(
              this.pageTitle.getTitle().split(" -")[0] +
                " - " +
                utils.getAppTitle()
            )
          : this.pageTitle.setTitle(utils.getAppTitle());
        this.portalBaseUrl = response["portalBaseUrl"];
        this.defaultLogoUrl = response["defaultLogo"].url;
        if (response["name"] && response["website"]) {
          this.pptText = response["name"];
          this.pptRedirectUrl = response["website"];
        } else if (response["name"] && !response["website"]) {
          this.pptText = response["name"];
          this.pptRedirectUrl = "";
        } else if (!response["name"] && response["website"]) {
          this.pptText = SIBConstants.APP_TITLE;
          this.pptRedirectUrl = response["website"];
        } else {
          this.pptText = SIBConstants.APP_TITLE;
          this.pptRedirectUrl = "";
        }

        this.getLogosUrls();
        this.isLoading = false;
      }
    }),
      (error) => {
        this.isLoading = false;
      };
  }

  downloadPocXls() {
    if (this.xlsLoader.searchEvent) {
      this.pointOfContactService
        .downloadPocXls("XLS", this.xlsLoader.searchEvent)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "Poc.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.flexItemsXlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadContractXls() {
    if (this.xlsLoader.searchEvent) {
      this.contractService
        .downloadFile("XLSX", this.xlsLoader.searchEvent)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "Contracts.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          this.notificationServcie.success("Downloaded XLSX file successfully", "Downloaded XLSX");
        });
    }
  }

  downloadSalesChartXls() {
    if (this.xlsLoader.searchEvent) {
      this.salesChartService
        .downloadSalesChartXls("XLSX", this.xlsLoader)
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            if (this.xlsLoader.billed) {
              if (this.xlsLoader.invoiceType === "invoice") {
                anchor.download = "Billed-Items.xlsx";
              } else if (this.xlsLoader.invoiceType === "migratedInvoice") {
                anchor.download = "Migrated-Billed-Items.xlsx";
              }
              // anchor.download = "Billed-Items.xlsx";
            } else if (!this.xlsLoader.billed) {
              anchor.download = "Unbilled-Items.xlsx";
            }
            // anchor.download = "sales.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;

            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  /**
   * @description Campaign ppt download from backend
   * @author Divya Sachan
   * @date 2019-10-14
   * @memberof AppTopBar
   */
  downloadCampaignPptBeta() {
    if (this.pptloader.data) {
      if (!this.pptloader.pptData) {
        this.notificationServcie.error(
          "PPT Download Error",
          "Please refresh and retry"
        );
      } else {
        this.campaignService
          .downloadFile(
            this.pptloader.data.id,
            this.pptloader.fileType,
            null,
            null,
            this.pptloader.pptData
          )
          .subscribe(
            (response: any) => {
              let blob;
              const anchor = document.createElement("a");
              blob = new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              });
              anchor.download = this.setCampaignPptName(
                this.pptloader.pptData.tabIndex
              );
              const url = URL.createObjectURL(blob);
              anchor.href = url;
              anchor.click();
              this.pptloader.isLoading = false;
              this.pptloader.text = null;
              // this.pptsDownloadInProgress.pop();
              this.campaignPptDownloads.shift();
              this.$pptDownloadCompletedSubject.next(true);
              if (!this.campaignPptDownloads.length) {
                this.createLoaderResetObject(this.resetPptloader);
                this.loaderSubjects.campaignPptLoader.next(this.resetPptloader);
                this.campaignPptDownloads = [];
              }
            },
            (error) => {
              this.pptloader.isLoading = false;
              this.pptloader.text = null;

              const errorObject = ErrorUtil.getErrorObject(error);
              if (errorObject.code === 417 || errorObject.code === 412) {
                this.notificationServcie.info(
                  errorObject.message
                    ? errorObject.message
                    : NotificationsMessages.TRY_AGAIN,
                  NotificationsMessages.INFORMATION
                );
              } else {
                this.notificationServcie.error(
                  errorObject.message
                    ? errorObject.message
                    : NotificationsMessages.TECHNICAL_ISSUE,
                  NotificationsMessages.ERROR
                );
              }
            }
          );
      }
    }
  }

  downloadCampaignTrackerPpt() {
    if (this.pptloader.data) {
      this.campaignService
        .downloadCampaignTrackerFile(
          this.pptloader.data.id,
          this.pptloader.fileType,
          this.tenantId,
          null,
          this.pptloader.pptData
        )
        .subscribe((response: any) => {
          let blob;
          const anchor = document.createElement("a");
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          });
          anchor.download = this.setCampaignPptName(
            this.pptloader.pptData.tabIndex
          );
          const url = URL.createObjectURL(blob);
          anchor.href = url;
          anchor.click();
          this.pptloader.isLoading = false;
          this.pptloader.text = null;
          this.pptsDownloadInProgress.pop();
          if (!this.pptsDownloadInProgress.length) {
            this.createLoaderResetObject(this.resetPptloader);
            this.loaderSubjects.campaignPptLoader.next(this.resetPptloader);
            this.pptsDownloadInProgress = [];
          }
        });
    }
  }

  /**
   * download plan template hoardings
   *
   * @memberof AppTopBar
   */
  downloadTemplateXlsx(media) {
    if (this.xlsLoader.searchEvent) {
      this.planTemplateService
        .downloadFile("XLSX", this.xlsLoader.searchEvent, media)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download =
            media === "hoardings"
              ? this.xlsLoader.searchEvent.name + "-Hoardings.xlsx"
              : media === "hoardingGroups"
              ? this.xlsLoader.searchEvent.name + "-Groups.xlsx"
              : this.xlsLoader.searchEvent.name + "-Kiosks.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadExpenseLevel1Xlsx() {
    if (this.xlsLoader.searchEvent) {
      this.inventoryExpensesService
        .downloadFile(
          "XLSX",
          this.xlsLoader.searchEvent,
          AppUrls.EXPENSE_REPORT
        )
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "Expense-Report.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  downloadExpenseLevel2Xlsx() {
    if (this.xlsLoader.searchEvent) {
      this.inventoryExpensesService
        .downloadFile(
          "XLSX",
          this.xlsLoader.searchEvent,
          AppUrls.EXPENSE_BREAKUP
        )
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = "Expense-Breakup.xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        });
    }
  }

  /**
   * rollbacks the campaign to plan
   *
   * @memberof AppTopBar
   */
  campaignRollback(campaignId) {
    if (campaignId) {
      this.loaderSubjects.loadingSubject.next(true);
      this.campaignService
        .get(null, AppUrls.ROLLBACK_TO_PLAN + AppUrls.SLASH + campaignId)
        .subscribe(
          () => {
            this.notificationServcie.success(
              "Campaign Rollbacked Successfully",
              "Campaign Rollback"
            );
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.campaignRollbackLoader.next(
              this.resetXlsLoader
            );
            this.loaderSubjects.loadingSubject.next(false);
            this.router.navigate(["/plans"]);
          },
          (error) => {
            // this.ro.isLoading = false;
            // this.pptloader.text = null;
            this.loaderSubjects.campaignRollbackLoader.next(
              this.resetXlsLoader
            );
            this.loaderSubjects.loadingSubject.next(false);
            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  /**
   * @description set name of ppt according to itemType
   * @author Divya Sachan
   * @date 2019-10-18
   * @returns
   * @memberof AppTopBar
   */
  setCampaignPptName(tabIndex) {
    let itemType = "";
    switch (tabIndex) {
      case 0: {
        itemType = "Sites";
        break;
      }
      case 1: {
        itemType = "Mount";
        break;
      }
      case 2: {
        itemType = "Monitored";
        break;
      }
      case 3: {
        itemType = "Unmount";
        break;
      }
    }
    return (
      this.pptloader.data.displayName +
      "-" +
      itemType +
      "-" +
      DateUtil.dategridFormatter(new Date()) +
      ".pptx"
    );
  }

  /**
   * gets logos url
   *
   * @memberof AppTopBar
   */
  getLogosUrls() {
    this.vendorService.get(null, "getLogo").subscribe(
      (response) => {
        if (response["data"].favIcon && response["data"].favIcon.url) {
          this._document
            .querySelector("link[rel*='icon']")
            .setAttribute("href", response["data"].favIcon.url);
        } else {
          this._document
            .querySelector("link[rel*='icon']")
            .setAttribute("href", "assets/layout/images/showitmax-icon.png");
        }
        if (response["data"].portalLogo && response["data"].portalLogo.url) {
          this.logoUrl = response["data"].portalLogo.url; //for portal logo
        } else {
          this.logoUrl = this.defaultLogoUrl;
        }
        if (response["data"].logoDetail && response["data"].logoDetail.url) {
          this.pptEmailUrl = response["data"].logoDetail.url; //for ppt email logo
        } else {
          this.pptEmailUrl = this.defaultLogoUrl;
        }
      },
      (error) => {
        this.notificationServcie.error(
          error.error.message
            ? error.error.message
            : NotificationsMessages.TECHNICAL_ISSUE,
          NotificationsMessages.REFRESH
        );
      }
    );
  }
  /**
   * @description download ROI XLS
   * @author Divya Sachan
   * @date 2019-11-13
   * @memberof AppTopBar
   */
  downloadRoiContractDataXls() {
    if (this.xlsLoader.searchEvent) {
      this.dashboardService
        .downloadRoiContractDataXls("XLS", this.xlsLoader.searchEvent)
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "ROI.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.roiContractDataXlsLoader.next(
              this.resetXlsLoader
            );
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;
            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  /**
   * @description download ROI inventory XLS
   * @author Divya Sachan
   * @date 2019-11-13
   * @memberof AppTopBar
   */
  downloadRoiContractInventoryDataXls() {
    if (this.xlsLoader.searchEvent) {
      this.dashboardService
        .downloadRoiContractInventoryDataXls("XLS", this.xlsLoader)
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = this.xlsLoader.data + "-ROI Item.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.roiContractInventoryDataXlsLoader.next(
              this.resetXlsLoader
            );
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;
            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  /**
   * @description download Untapped ROI XLS
   * @author Divya Sachan
   * @date 2019-11-15
   * @memberof AppTopBar
   */
  downloadUntappedRoiDataXls() {
    if (this.xlsLoader.searchEvent) {
      this.dashboardService
        .downloadUntappedRoiDataXls("XLS", this.xlsLoader.searchEvent)
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download = "NPC.xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.untappedRoiDataXlsLoader.next(
              this.resetXlsLoader
            );
          },
          (error) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;
            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  /**
   * @description set file name according to the source
   * @author Divya Sachan
   * @date 2020-01-30
   * @param {*} loaderObject
   * @returns
   * @memberof AppTopBar
   */
  setFileNameAccToSource(loaderObject) {
    if (loaderObject.source === "Contract") {
      return loaderObject.source;
    } else {
      return undefined;
    }
  }

  /**
   * @description  download XLS for media types
   * @author Divya Sachan
   * @date 2020-02-18
   * @memberof AppTopBar
   */
  downloadInventoryXls() {
    if (this.xlsLoader.searchEvent) {
      this.inventoryService
        .downloadFile("XLSX", this.xlsLoader.searchEvent)
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = this.setFileNameAccToSource(this.xlsLoader)
            ? this.setFileNameAccToSource(this.xlsLoader) +
              "-" +
              this.xlsLoader.mediaType +
              ".xlsx"
            : this.xlsLoader.mediaType + ".xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        }),
        catchError((err) => {
          this.xlsLoader.isLoading = false;
          this.xlsLoader.text = null;
          const errorObject = ErrorUtil.getErrorObject(err);
          if (errorObject.code === 417 || errorObject.code === 412) {
            this.notificationServcie.info(
              errorObject.message
                ? errorObject.message
                : NotificationsMessages.TRY_AGAIN,
              NotificationsMessages.INFORMATION
            );
          } else {
            this.notificationServcie.error(
              errorObject.message
                ? errorObject.message
                : NotificationsMessages.TECHNICAL_ISSUE,
              NotificationsMessages.ERROR
            );
          }
          return err;
        });
    }
  }

  onNotificationBellClick($event) {
    this.showNotifications = true;
    this.notificationOverlay.show($event);
  }

  hideNotificationOverlay() {
    this.showNotifications = false;
    this.notificationOverlay.hide();
  }

  setActivityLog(actionEvent, displayKey, primaryKey, module) {
    const activityGridPayload = [];
    activityGridPayload.push({
      actionEvent: actionEvent,
      additoinalDesc: displayKey,
      changes: [],
      displayKey: displayKey,
      module: module,
      primaryKey: primaryKey,
      responsiblePersonEmailId: this.emailId,
    });
    this.changesService
      .create(activityGridPayload, null, AppUrls.SAVE)
      .subscribe(
        (response) => {
          this.changesService.resetData();
        },
        (error) => {
          this.notificationServcie.error(
            error.error
              ? error.error.message
                ? error.error.message
                : error.message
              : "Some technical issue",
            "Error!!"
          );
        }
      );
  }

  downloadMounterReport(type) {
    if (this.xlsLoader.searchEvent) {
      this.campaignItemService
        .downloadMounterReport("XLSX", this.xlsLoader.searchEvent, type)
        .subscribe(
          (response) => {
            let blob;
            let url;
            let anchor;
            blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            url = URL.createObjectURL(blob);
            anchor = document.createElement("a");
            anchor.download =
              type + "-" + DateUtil.dategridFormatter(new Date()) + ".xlsx";
            anchor.href = url;
            anchor.click();
            this.createLoaderResetObject(this.resetXlsLoader);
            this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
          },
          (err) => {
            this.xlsLoader.isLoading = false;
            this.xlsLoader.text = null;
            const errorObject = ErrorUtil.getErrorObject(err);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
            return err;
          }
        );
    }
  }

  downloadAdvanceSearchInventoryXls() {
    if (this.xlsLoader.searchEvent && this.xlsLoader.mediaType) {
      this.inventoryService
        .downloadFile("XLSX", this.xlsLoader.searchEvent, "advanceSearch")
        .subscribe((response) => {
          let blob;
          let url;
          let anchor;
          blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = URL.createObjectURL(blob);
          anchor = document.createElement("a");
          anchor.download = this.setFileNameAccToSource(this.xlsLoader)
            ? this.setFileNameAccToSource(this.xlsLoader) +
              "-" +
              this.xlsLoader.mediaType +
              ".xlsx"
            : this.xlsLoader.mediaType + ".xlsx";
          anchor.href = url;
          anchor.click();
          this.createLoaderResetObject(this.resetXlsLoader);
          this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
        }),
        catchError((err) => {
          this.xlsLoader.isLoading = false;
          this.xlsLoader.text = null;
          const errorObject = ErrorUtil.getErrorObject(err);
          if (errorObject.code === 417 || errorObject.code === 412) {
            this.notificationServcie.info(
              errorObject.message
                ? errorObject.message
                : NotificationsMessages.TRY_AGAIN,
              NotificationsMessages.INFORMATION
            );
          } else {
            this.notificationServcie.error(
              errorObject.message
                ? errorObject.message
                : NotificationsMessages.TECHNICAL_ISSUE,
              NotificationsMessages.ERROR
            );
          }
          return err;
        });
    }
  }

  downloadConfirmationQuote() {
    if (
      this.quoteLoader &&
      this.quoteLoader.data &&
      this.quoteLoader.searchEvent
    ) {
      this.campaignService
        .printConfirmationQuote(
          this.quoteLoader.searchEvent,
          this.quoteLoader.data.id
        )
        .subscribe(
          (response) => {
            if (response) {
              let blob;
              let url;
              let anchor;
              blob = new Blob([response], { type: "application/pdf" });
              url = URL.createObjectURL(blob);
              anchor = document.createElement("a");
              anchor.download =
                this.quoteLoader.data.displayName +
                "-" +
                DateUtil.dategridFormatter(new Date()) +
                ".pdf";
              anchor.href = url;
              anchor.click();
              //   const blob = new Blob([response], { type: "application/pdf" });
              //   const url = URL.createObjectURL(blob);
              //   this.isLoading = false;
              //   window.open(url, "_blank").focus(); // need to disable adblock for this
            }
            this.createLoaderResetObject(this.resetQuoteLoader);
            this.loaderSubjects.confirmationQuoteLoader.next(
              this.resetQuoteLoader
            );
          },
          (error) => {
            this.quoteLoader.isLoading = false;
            this.quoteLoader.text = null;
            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
    }
  }

  updateInventoryPhotos() {
    if (
      this.inventoryPhotoUpdateLoader &&
      this.inventoryPhotoUpdateLoader.fileType === "UPDATE_PHOTOS"
    ) {
      let url = AppUrls.UPLOAD_CAMP_IMAGES;
      if (
        (this.inventoryPhotoUpdateLoader.data &&
          this.inventoryPhotoUpdateLoader.source === "CAMPAIGN") ||
        this.inventoryPhotoUpdateLoader.source === "INVENTORY"
      ) {
        this.inventoryPhotoUpdateLoader.data &&
        this.inventoryPhotoUpdateLoader.source === "CAMPAIGN"
          ? (url =
              AppUrls.UPLOAD_CAMP_IMAGES +
              AppUrls.SLASH +
              this.inventoryPhotoUpdateLoader.data)
          : "";
        this.inventoryService.get(null, url).subscribe(
          (response) => {
            if (response) {
              this.notificationServcie.success(
                "Inventory Photos updated Successfully.",
                "Inventory Photos"
              );
            } else {
              this.notificationServcie.error(
                "Some Technical Issue",
                "Server Error"
              );
            }
            this.inventoryService.$refreshAfterPhotoUpdate.next(
              this.inventoryPhotoUpdateLoader.source
            );
            this.createLoaderResetObject(this.resetInventoryPhotoUpdateLoader);
            this.loaderSubjects.inventoryPhotoUpdateLoader.next(
              this.resetInventoryPhotoUpdateLoader
            );
          },
          (error) => {
            this.inventoryPhotoUpdateLoader.isLoading = false;
            this.inventoryPhotoUpdateLoader.text = null;
            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
      } else if (
        this.inventoryPhotoUpdateLoader.data &&
        this.inventoryPhotoUpdateLoader.source === "PLAN"
      ) {
        url =
          AppUrls.UPLOAD_CAMP_IMAGES +
          AppUrls.SLASH +
          this.inventoryPhotoUpdateLoader.data;
        this.planTemplateService.get(null, url).subscribe(
          (response) => {
            if (response) {
              if (response.message === "Photos updated successfully.") {
                this.notificationServcie.success(
                  "Inventory Photos updated Successfully.",
                  "Inventory Photos"
                );
              } else {
                this.notificationServcie.info(
                  response.message && response.message.length
                    ? response.message
                    : "No photos to update in plan.",
                  "Inventory Photos"
                );
              }
            } else {
              this.notificationServcie.error(
                "Some Technical Issue",
                "Server Error"
              );
            }
            this.inventoryService.$refreshAfterPhotoUpdate.next(
              this.inventoryPhotoUpdateLoader.source
            );
            this.createLoaderResetObject(this.resetInventoryPhotoUpdateLoader);
            this.loaderSubjects.inventoryPhotoUpdateLoader.next(
              this.resetInventoryPhotoUpdateLoader
            );
          },
          (error) => {
            this.inventoryPhotoUpdateLoader.isLoading = false;
            this.inventoryPhotoUpdateLoader.text = null;
            const errorObject = ErrorUtil.getErrorObject(error);
            if (errorObject.code === 417 || errorObject.code === 412) {
              this.notificationServcie.info(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TRY_AGAIN,
                NotificationsMessages.INFORMATION
              );
            } else {
              this.notificationServcie.error(
                errorObject.message
                  ? errorObject.message
                  : NotificationsMessages.TECHNICAL_ISSUE,
                NotificationsMessages.ERROR
              );
            }
          }
        );
      }
    }
  }

  setSupplierDropdown() {
    this.supplierVendorService
      .create(this.searchEvent, this.searchEvent, AppUrls.SEARCH)
      .subscribe((response) => {
        if (response["data"]["content"]) {
          let supplierNameList = response["data"]["content"].map(
            (item) => item.name
          );
          localStorage.setItem(
            "supplierMultiselect",
            JSON.stringify(
              utils.createDropdownFromStringArray(supplierNameList, false)
            )
          );
        }
      }),
      (error) => {
        this.notificationServcie.error(
          error.error.message
            ? error.error.message
            : "Unable to fetch supplier list.",
          "Please Refresh."
        );
      };
  }

  setPptConfig(type, module?) {
    switch (type) {
      case "Customer Configured": {
        return this.pptDownloads[0].pptData.plan.customer.pptConfig;
      }
      case "Vendor Specific": {
        return this.pptDownloads[0].vendorPptConfig;
      }
      default: {
        return null;
      }
    }
  }
}
